<template>
  <div class="certifiedPage">
    <div class="certified-content">
      <van-form @submit="onSubmit">
        <van-field
          label-width="80px"
          v-model="dataInfo.fullName"
          label="姓名"
          placeholder="请填写姓名"
          clearable
          @input="getCheckDisabled"
          :rules="rules.fullName"
        />
        <van-field
          label-width="80px"
          v-model="dataInfo.identityTypeName"
          name="identityType"
          label="证件类型"
          placeholder="请选择证件类型"
          :readonly="identityTypes.length <= 0"
          is-link
          @click="identityTypes.length > 0 ? (showIdentityType = true) : (showIdentityType = false)"
        >
        </van-field>
        <van-action-sheet
          v-model="showIdentityType"
          :actions="identityTypes"
          @select="onIdentityTypeSelect"
        />
        <van-field
          label-width="80px"
          v-model="dataInfo.identityId"
          label="证件号码"
          placeholder="请填写证件号码"
          clearable
          @input="getCheckDisabled"
          :rules="
            dataInfo.identityType === 'CRED_PSN_CH_IDCARD'
              ? rules.identityId
              : dataInfo.identityType === 'CRED_PSN_PASSPORT'
              ? rules.passport
              : rules.gatePassCode
          "
        />
        <div class="submit-button">
          <van-button round block type="info" native-type="submit" :disabled="isDisabledSubmit">
            确定
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { getCode, certifyByPhone, getCardTypes } from '@/api/home';
import { certifyByEsign } from '@/api/contract';
import { isMobile, identityId, isTellCode, gatePassCode, passport } from '@/utils/validator';
import { Toast } from 'vant';
export default {
  name: 'CertifiedPage',
  data() {
    return {
      dataInfo: {
        fullName: '',
        identityType: '',
        identityTypeName: '',
        identityId: '',
        phoneNum: '',
        verificationCode: '',
      },
      rules: {
        fullName: [{ required: true, message: '姓名不能为空' }],
        identityId: [{ validator: identityId, message: '身份证输入错误' }],
        gatePassCode: [{ validator: gatePassCode, message: '通行证输入错误' }],
        passport: [{ validator: passport, message: '护照输入错误' }],
        phoneNum: [{ validator: isMobile, message: '手机号输入错误' }],
        verificationCode: [{ validator: isTellCode, message: '请输入正确的验证码' }],
      },
      wait: 60,
      buttonContent: '获取验证码',
      isDisabled: false, // 验证码按钮
      isDisabledSubmit: true, // 提交按钮
      show: false,
      identityTypes: [],
      showIdentityType: false,
    };
  },
  mounted() {
    this.searchCardTypes();
  },
  methods: {
    onIdentityTypeSelect(value) {
      this.dataInfo.identityTypeName = value.name;
      this.dataInfo.identityType = value.code;
      this.showIdentityType = false;
      this.getCheckDisabled();
    },
    async searchCardTypes() {
      try {
        let res = await getCardTypes();
        if (res.status === 'complete') {
          this.identityTypes = [...res.resultData];
        } else {
          Toast('查询证件类型错误');
        }
      } catch (error) {
        Toast('查询证件类型错误');
      }
    },
    // 提交
    async onSubmit(values) {
      let arg = {
        businessFlowId: this.$route.query.businessFlowId,
        code: this.dataInfo.verificationCode === '' ? undefined : this.dataInfo.verificationCode,
        idNo: this.dataInfo.identityId === '' ? undefined : this.dataInfo.identityId,
        phone: this.dataInfo.phoneNum === '' ? undefined : this.dataInfo.phoneNum,
        realName: this.dataInfo.fullName === '' ? undefined : this.dataInfo.fullName,
        identityType: this.dataInfo.identityType === '' ? undefined : this.dataInfo.identityType,
      };
      const { status, resultData } = await certifyByEsign(arg);
      if (status === 'complete') {
        let link = document.createElement('a');
        link.style.display = 'none';
        link.href = resultData;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        Toast('提交信息错误');
      }
    },
    // 下一步是否置灰
    getCheckDisabled() {
      const { fullName, identityId, phoneNum, verificationCode, identityType } = this.dataInfo;
      let flag = false;
      if (fullName && identityId && identityType) {
        flag = false;
      } else {
        flag = true;
      }
      this.isDisabledSubmit = flag;
    },
  },
};
</script>

<style lang="less" scoped>
.certifiedPage {
  background: #ffffff;
  .certified-content {
    padding-top: 30px;
    /deep/.van-cell {
      font-size: 16px;
    }
    .sms-button {
      color: #999999;
      border: 1px solid #999999;
      border-radius: 100px;
      color: #4466ad;
      border: 1px solid #4466ad;
      .van-button__text {
        font-size: 12px;
      }
    }
    .submit-button {
      border-radius: 5px;
      margin: 100px 16px 0px;
      padding-bottom: 100px;
      p {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #999999;
        text-align: center;
        line-height: 17px;
        margin-top: 12px;
        em {
          font-style: normal;
          color: #5f97f4;
        }
      }
      .van-button {
        font-size: 16px;
        border-radius: 8px;
      }
      /deep/.van-button--mini {
        padding: 0 9px;
      }
    }
  }
  .popupContent {
    p {
      text-align: center;
      color: #666;
    }
    .list {
      ul {
        text-align: center;
        margin: 0px 16px;
        li {
          height: 35px;
          line-height: 35px;
          color: #666666;
          font-size: 14px;
          border-bottom: 1px solid #cccccc;
        }
        li:last-child {
          border-bottom: 0px solid transparent;
        }
      }
    }
  }
}
</style>
